/* eslint-disable import/prefer-default-export */
import produce from 'immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type HistoryStore = {
  lastUrl: string[]
  lastSearch: string[]
  setHistory: (path: string) => void
  pop: (stack?: 'urls' | 'search') => void
}

export const useHistoryStore = create<HistoryStore>()(
  devtools((set) => ({
    lastUrl: ['/'],
    lastSearch: ['/'],
    setHistory: (history): void =>
      set(
        produce((draft) => {
          if (/^\/jobs\/[^/]+\/[^/]+(?!\/[^/])$/.test(history)) {
            if (history !== draft.lastSearch[draft.lastSearch.length - 1]) {
              draft.lastSearch.push(history)
            }
          }
          if (history !== draft.lastUrl[draft.lastUrl.length - 1]) {
            draft.lastUrl.push(history)
          }
        }),
      ),
    pop(stack = 'urls'): void {
      set(
        produce((draft) => {
          if (stack === 'search') {
            draft.lastSearch.pop()
            return
          }

          draft.lastUrl.pop()
        }),
      )
    },
  })),
)
