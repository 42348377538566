'use client'

import { useEffect, useState } from 'react'

import Image from 'next/image'

import Button from '@components/buttons/button'
import Link from '@components/link'
import useCookiesConsent from '@hooks/useCookiesConsent'
import useIsLoggedIn from '@hooks/user/useIsLoggedIn'

import cookieIcon from '@images/index/cookiespopup/cookie.svg'

import styles from './cookies-popup.module.scss'

export default function CookiesPopup(): JSX.Element {
  const [areCookiesAccepted, setCookiesConsent] = useCookiesConsent()
  const { isLoggedIn } = useIsLoggedIn()
  const [isOpen, setIsOpen] = useState<boolean>(!areCookiesAccepted)

  const reject = (): void => {
    setIsOpen(false)
    setCookiesConsent(false)
  }

  useEffect(() => {
    if (isLoggedIn) {
      setIsOpen(false)
      setCookiesConsent(true)
    }
  }, [isLoggedIn, setCookiesConsent])

  if (!isOpen) {
    return <></>
  }

  return (
    <section
      aria-labelledby="cookie-popup-title"
      className={styles['cookies-popup']}
      style={{
        display: isOpen ? 'block' : 'none',
      }}
    >
      <div className={styles.header}>
        <Image src={cookieIcon} alt="Cookies" width={51} height={40} />
        <div>
          <p id="cookie-popup-title">This website uses cookies.</p>
          <Link href={`/privacy`} className={styles['read-more']} target="_blank">
            Privacy Policy
          </Link>
        </div>
      </div>
      <p id="cookie-popup-content" className={styles.content}>
        This website may use “cookies” or similar electronic tracking tools/technologies to collect information to
        understand the user’s individual interests and preferences and to improve your user experience and the overall
        quality of our Services.
      </p>
      <Link href={`/privacy`} target="_blank" className={styles.link}>
        Read more in the Privacy Policy
      </Link>
      <div className={styles['btn-container']}>
        <Button noDefaultStyle onClick={reject} className={styles.reject}>
          Reject
        </Button>
        <Button
          variant={'yellow'}
          className={styles.accept}
          onClick={(): void => {
            setCookiesConsent(true)
            setIsOpen(false)
          }}
        >
          Accept
        </Button>
      </div>
    </section>
  )
}
